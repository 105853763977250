/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        $(document).ready(function(){
          $('.main-menu').find('li > a').hover(function(){
            $(this).children('.icon-container').stop().slideDown('fast');
          },function(){
            $(this).children('.icon-container').stop().slideUp('fast');
          });

          var homeURL = $('.anchor-history').find('a').attr('href');
          $('.anchor-history').find('a').attr('href',homeURL+'#history');

          $('.go-up').click(function(){
            $("html, body").animate({ scrollTop: "0px" });
          });

          $(window).resize(function(){
            if($(window).width() >= 768){
              $(".main-nav").sticky({topSpacing:0, zIndex: 999999});
            } else {
              $(".main-nav").unstick();
            }
          });

          if($(window).width() >= 768){
            $(".main-nav").sticky({topSpacing:0, zIndex: 999999});
          }

          if($('.homepage').length == 0){
            var homeURL = $('.brand').attr('href');
            $('.main-menu').find('a').each(function(){
              if(!$(this).parent('li').hasClass('anchor-primary')){
                var linkSharp = $(this).attr('href');
                $(this).attr('href',homeURL+linkSharp); 
              }
            }); 
          } else {
            $('.main-menu').find('a').click(function(e){
              if(!$(this).parent('li').hasClass('anchor-primary')){
                e.preventDefault();

                var anchor = $(this).attr('href');
                //console.log(anchor);

                var scrollWhere = $(anchor);
                //console.log(scrollWhere);

                if(scrollWhere.length == 0){
                  anchor = anchor.replace('#', '.');
                  scrollWhere = $(anchor);
                }
  
                $(window).scrollTo(scrollWhere, {duration:1000});
              }
            });
          }

          $(function () {
            $('[data-toggle="tooltip"]').tooltip();
          });

          /* you can access and overwrite all defaults using $.featherlight.defaults */
          $('.special').featherlight({ resetCss: true });

        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        $(window).load(function() {
          $('.flexslider').flexslider({
            animation: "fade",
            smoothHeight: 'false',
            controlsContainer: $(".arrows"),
            controlNav: false,
            directionNav: true,
            useCSS: false,
            start: function(){
            }
          });
        });

        $(document).ready(function(){
          $('.read-more a').click(function(e){
            e.preventDefault();
            $('.read-text').slideToggle("fast","swing",function(){
              jQuery(window).trigger('resize').trigger('scroll');
            });
          });  

          function resized(){
            jQuery(window).trigger('resize');
          }

          $('.navbar-toggle').click(function(){
            setTimeout(resized, 1000); 
          });
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.